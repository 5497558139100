import axios from 'axios';

(function ($) {
    'use strict';

    // Create custom loading finished event
    const loadingFinishedEvent = new CustomEvent("loading-finished");

    // Variables to store timeout
    let qtyTimeout;

    // Set default axios parameters
    const axiosReq = axios.create({
        responseType: 'json',
        timeout: 10000,
        headers: {'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
    });

    // Show error notification
    const showError = (message, icon) => {
        window.showAlert('danger', message, icon);
    }

    // Show success notification
    const showSuccess = (message, icon) => {
        window.showAlert('success', message, icon);
    }

    // Reload cart datas
    const reloadCartDatas = () => {
        return new Promise((resolve, reject) => {
            axiosReq.get(window.siteUrl + '/ajax/cart')
            .then(res => {
                const responseData = res.data;
                if (!responseData.error) {
                    document.querySelector('.side-panel--cart').innerHTML = responseData.data.html;
                    document.querySelectorAll('.button-action--cart').forEach(element => {
                        element.querySelector('span').innerHTML = responseData.data.count;
                    });
                }
                resolve();
            });
        });
    }

    // Reload wishlist datas
    const reloadWishlistDatas = () => {
        return new Promise((resolve, reject) => {
            axiosReq.get(window.siteUrl + '/ajax/wishlist')
                .then(res => {
                    const responseData = res.data;
                    if (!responseData.error) {
                        document.querySelector('.side-panel--wishlist').innerHTML = responseData.data.html;
                        document.querySelectorAll('.button-action--wishlist').forEach(element => {
                            element.querySelector('span').innerHTML = responseData.data.count;
                        });
                    }
                    resolve();
                });
        });
    }


    // Update cart datas
    const updateCartDatas = (form) => {
        for (const pair of new FormData(form).entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
        }
        return new Promise((resolve, reject) => {
            axiosReq.post(
                form.getAttribute('action'),
                new FormData(form),
            ).then(res => {
                resolve(res);
            });
        });
    }


    // Click on button loading
    onDocumentEvent('click', '.button-loading', (_self, e) => {
        e.preventDefault();
        _self.classList.add('is-loading');
        _self.setAttribute('disabled', true);
    });
    // When a loading is finished for the button loading
    document.querySelectorAll('.button-loading').forEach(element => {
        element.addEventListener('loading-finished', function() {
            this.classList.remove('is-loading');
            this.removeAttribute('disabled');
        });
    });



    // Click on the close icon of an alert
    onDocumentEvent('click', '.alert__close', (_self, e) => {
        _self.closest('.alert').remove();
    });


    // Click on the close icon of a side-panel
    onDocumentEvent('click', '.side-panel__close', (_self, e) => {
        _self.closest('.side-panel').classList.remove('active');
    });


    // Click on "Add to cart" button
    onDocumentEvent('click', '.add-to-cart', (_self, e) => {
        e.preventDefault();

        _self.setAttribute('disabled', true);

        axiosReq.post(_self.getAttribute('data-url'), {
            id: _self.getAttribute('data-id'),
        }).then(res => {
            const responseData = res.data;
            _self.removeAttribute('disabled');

            if (responseData.error) {
                showError(responseData.message);
                return false;
            }

            if (_self.getAttribute('name') === 'checkout' && res.data.next_url !== undefined) {
                showSuccess(responseData.message, 'fig-shopping-cart-add');
                window.location.href = res.data.next_url;
            } else {
                reloadCartDatas().then(() => {
                    showSuccess(responseData.message, 'fig-shopping-cart-add');
                    _self.dispatchEvent(loadingFinishedEvent);
                });
            }
        });
    });


    // Click on remove cart button
    onDocumentEvent('click', '.remove-from-cart', (_self, e) => {
        e.preventDefault();
        const url = _self.getAttribute('data-url');
        
        axiosReq.get(url)
            .then(res => {
                const responseData = res.data;

                if (responseData.error) {
                    showError(res.message);
                    return false;
                }

                if (_self.closest('.form--shopping-cart') != null) {
                    reloadHTML('.form--shopping-cart', axiosReq);
                }

                reloadCartDatas().then(() => {
                    showSuccess(responseData.message, 'fig-trash');
                    _self.dispatchEvent(loadingFinishedEvent);
                });
            });
    });


    // Click on "Add to wishlist" button
    onDocumentEvent('click', '.add-to-wishlist', (_self, e) => {
        e.preventDefault();
        const url = _self.getAttribute('data-url');

        axiosReq.post(url)
            .then(res => {
                const responseData = res.data;

                if (responseData.error) {
                    showError(responseData.message, 'fig-heart');
                    return false;
                }

                reloadWishlistDatas().then(() => {
                    showSuccess(responseData.message, 'fig-heart');
                    _self.dispatchEvent(loadingFinishedEvent);
                });
            });
    });


    // Click on remove cart button
    onDocumentEvent('click', '.remove-from-wishlist', (_self, e) => {
        e.preventDefault();
        const url = _self.getAttribute('data-url');

        axiosReq.delete(url)
            .then(res => {
                const responseData = res.data;

                if (responseData.error) {
                    showError(res.message);
                    return false;
                }

                reloadWishlistDatas().then(() => {
                    showSuccess(responseData.message, 'fig-trash');
                    _self.dispatchEvent(loadingFinishedEvent);
                });
            });
    });


    // Click on confirm button of dialog
    // document.querySelectorAll('.dialog__confirm').forEach(element => {
    //     element.addEventListener('click', function(e) {
    //         const dialog = this.closest('.dialog');
    //         const dialogId = dialog.getAttribute('id');
    //         const dialogAjax = dialog.getAttribute('data-ajax');

    //         if (dialogAjax != undefined) {
    //             axiosReq.get(dialogAjax)
    //                 .then(res => {
    //                     const responseData = res.data;
                        
    //                     if (dialogId == 'remove-cart-dialog') {
    //                         if (!responseData.error) {
    //                             showSuccess(responseData.message, 'fig-trash');
    //                             reloadCartDatas();
    //                             dialog.close();
    //                         }
    //                     }

    //                 });
    //         }
    //     });
    // });


    // Click on cancel or close button of dialog
    document.querySelectorAll('.dialog__cancel, .dialog__close').forEach(element => {
        element.addEventListener('click', function(e) {
            e.preventDefault();
            this.closest('.dialog').close();
        });
    });


    // Click to add a quantity of the product
    onDocumentEvent('click', '.qty__add', (_self, e) => {
        e.preventDefault();
        e.stopPropagation();
        const input = _self.previousElementSibling;
        let currentVal = parseInt(input.value, 10);
        input.value = currentVal + 1;
        
        if (_self.closest('.form--shopping-cart') != null) {
            clearTimeout(qtyTimeout);
            qtyTimeout = setTimeout(function() {
                updateCartDatas(_self.closest('.form--shopping-cart')).then(res => {
                    showSuccess(res.data.message);
                    reloadHTML('.form--shopping-cart', axiosReq);
                    reloadCartDatas();
                });
            }, 500);
        }
    });


    // Click to remove a quantity of the product
    onDocumentEvent('click', '.qty__remove', (_self, e) => {
        e.preventDefault();
        e.stopPropagation();
        const input = _self.nextElementSibling;
        let currentVal = parseInt(input.value, 10);

        if (currentVal > 0) {
            input.value = currentVal - 1;
        }
        
        if (_self.closest('.form--shopping-cart') != null) {
            clearTimeout(qtyTimeout);
            qtyTimeout = setTimeout(function() {
                updateCartDatas(_self.closest('.form--shopping-cart')).then(res => {
                    showSuccess(res.data.message);
                    reloadHTML('.form--shopping-cart', axiosReq);
                    reloadCartDatas();
                });
            }, 500);
        }
    });


    // Change a quantity of the product inside the input
    onDocumentEvent('change', '.qty__input', (_self, e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (_self.closest('.form--shopping-cart') != null) {
            clearTimeout(qtyTimeout);
            qtyTimeout = setTimeout(function() {
                updateCartDatas(_self.closest('.form--shopping-cart')).then(res => {
                    showSuccess(res.data.message);
                    reloadHTML('.form--shopping-cart', axiosReq);
                    reloadCartDatas();
                });
            }, 500);
        }
    });


    // Click on add to cart button of the ".add-to-cart-form" form element
    const addToCartFormButton = document.querySelector('.add-to-cart-form button[type=submit]');
    if (addToCartFormButton != undefined) {
        addToCartFormButton.addEventListener('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
    
            const _self = this;
    
            _self.closest('form').querySelectorAll('.error-message, .success-message').forEach(element => {
                element.css('display','none');
            });
    
            axiosReq.post(_self.closest('form').getAttribute('action'), new FormData(_self.closest('form')))
                .then(res => {
                    const responseData = res.data;
    
                    if (responseData.error) {
                        _self.closest('form').querySelector('.error-message').innerHTML = responseData.message;
                        _self.closest('form').querySelector('.error-message').css('display','block');
                        showError(responseData.message);
                        return false;
                    }
    
                    if (_self.getAttribute('name') === 'checkout' && res.data.next_url !== undefined) {
                        showSuccess(responseData.message, 'fig-shopping-cart-add');
                        window.location.href = res.data.next_url;
                    } else {
                        reloadCartDatas().then(() => {
                            showSuccess(responseData.message, 'fig-shopping-cart-add');
                            _self.dispatchEvent(loadingFinishedEvent);
                        });
                    }
                });
        });
    }


    // Click on the coupon code button
    onDocumentEvent('click', '.coupon-code__button',  (_self, e) => {
        e.preventDefault();
        _self.closest('.coupon-code').classList.add('active');
    });


    // Click on the "Apply" for coupon code
    onDocumentEvent('click', '.apply-coupon-code',  (_self, e) => {

        axiosReq.post(_self.getAttribute('data-url'), {
            coupon_code: _self.closest('.form-coupon-wrapper').querySelector('[name="coupon_code"]').value,
        }).then(res => {
            console.log(res);
            if (!res.error) {
                reloadHTML('.form--shopping-cart', axiosReq, 'applied_coupon=1').then(() => {
                    showSuccess(res.data.message);
                    _self.dispatchEvent(loadingFinishedEvent);
                });
            } else {
                document.querySelector('.coupon-error-msg').innerHTML = `<div class="notif notif--danger">${res.message}</div>`;
                _self.dispatchEvent(loadingFinishedEvent);
            }
        });

    });


    // Click on the trash to remove the coupon code
    onDocumentEvent('click', '.remove-coupon-code',  (_self, e) => {

        axiosReq.post(_self.getAttribute('data-url')).then(res => {
            console.log(res);
            if (!res.error) {
                reloadHTML('.form--shopping-cart', axiosReq).then(() => {
                    showSuccess(res.data.message);
                    _self.dispatchEvent(loadingFinishedEvent);
                });
            } else {
                document.querySelector('.coupon-error-msg').innerHTML = `<div class="notif notif--danger">${res.message}</div>`;
                _self.dispatchEvent(loadingFinishedEvent);
            }
        });

    });

    // $(document).on('click', '.btn-remove-coupon-code', event => {
    //     event.preventDefault();
    //     let _self = $(event.currentTarget);
    //     _self.prop('disabled', true).addClass('btn-disabled').addClass('button-loading');

    //     $.ajax({
    //         url: _self.data('url'),
    //         type: 'POST',
    //         headers: {
    //             'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //         },
    //         success: res => {
    //             if (!res.error) {
    //                 $('.form--shopping-cart').load(window.location.href + ' .form--shopping-cart > *', function () {
    //                     _self.prop('disabled', false).removeClass('btn-disabled').removeClass('button-loading');
    //                 });
    //             } else {
    //                 $('.coupon-error-msg .text-danger').text(res.message);
    //                 _self.prop('disabled', false).removeClass('btn-disabled').removeClass('button-loading');
    //             }
    //         },
    //         error: data => {
    //             if (typeof (data.responseJSON) !== 'undefined') {
    //                 if (data.responseJSON.errors !== 'undefined') {
    //                     $.each(data.responseJSON.errors, (index, el) => {
    //                         $.each(el, (key, item) => {
    //                             $('.coupon-error-msg .text-danger').text(item);
    //                         });
    //                     });
    //                 } else if (typeof (data.responseJSON.message) !== 'undefined') {
    //                     $('.coupon-error-msg .text-danger').text(data.responseJSON.message);
    //                 }
    //             } else {
    //                 $('.coupon-error-msg .text-danger').text(data.status.text);
    //             }
    //             _self.prop('disabled', false).removeClass('btn-disabled').removeClass('button-loading');
    //         }
    //     });
    // });



})();