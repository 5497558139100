/**
 * Event delegation function
 * 
 * This function can be used for elements that are generated dynamically in the DOM,
 * to avoid loosing event listeners with a classic "addEventListener" function on the element itself.
 * 
 * @param {String} type 
 * @param {String} selector 
 * @param {Function} callback 
 * @return {void}
 */
window.onDocumentEvent = (type, selector, callback) => {
    if (type == undefined || selector == undefined) {
        if (type == undefined) {
            throw new Error('The "type" of onDocumentEvent function must be specified');
        }
        if (selector == undefined) {
            throw new Error('The "selector" of onDocumentEvent function must be specified');
        }
    } else {
        document.addEventListener(type, function(e) {
            const elements = document.querySelectorAll(selector);
            elements.forEach(element => {
                if (e.target && (e.target == element || element.contains(e.target))) {
                    let _self;
                    if (e.target.isSameNode(element)) {
                        _self = e.target;
                    } else {
                        const elementClasses = element.classList != undefined ? `.${element.classList.value.replace(/ /g,".")}` : undefined;
                        const elementId = element.getAttribute('id');
                        _self = elementClasses != undefined ? e.target.closest(elementClasses) : e.target.closest(elementId);
                    }
                    callback(_self, e);
                }
            });
        });
    }    
}


window.reloadHTML = (selector, axios, parameters) => {
    let url = window.location.href;

    if (parameters != undefined) {
        url += `?${parameters}`;
    }

    return new Promise((resolve, reject) => {
        axios({
            url: url,
            responseType: 'document',
        }).then(res => {
            const template = res.data;
            const element = document.querySelector(selector);
            const newElementHTML = template.querySelector(selector).innerHTML;

            element.innerHTML = newElementHTML;

            resolve();
        });
    });
}


/**
 * Show a notification alert on the screen
 * 
 * @param {String} messageType 
 * @param {String} message 
 * @param {String} icon 
 */
window.showAlert = (messageType, message, icon) => {
    const alertContainer = document.querySelector('.alert-container');
    
    if (messageType && message !== '') {
        const alertId = Math.floor(Math.random() * 1000);
        const alertNew = alertContainer.querySelector('.alert--new');

        const html = `<div id="alert-${alertId}" class="alert alert--${messageType} alert--new">
                        <span class="alert__close"></span>
                        <i class="alert__icon ${icon === undefined ? 'fig-check' : icon}"></i>
                        <span class="alert__message">${message}</span>
                    </div>`;

        alertNew != undefined ? alertNew.classList.remove('alert--new') : null;
        alertContainer.innerHTML += html;
        
        window.setTimeout(() => {
            document.querySelector(`#alert-${alertId}`).remove();
        }, 3000);
    }
}